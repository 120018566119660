import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const AffiliateTermsAndCondition = (props) => {
    const [affiliateTermsAndConditions, setAffiliateTermsAndCondition] = useState([])

    const title = affiliateTermsAndConditions?.data?.title
    const subTitle = affiliateTermsAndConditions?.data?.sub_title
    const description = affiliateTermsAndConditions?.data?.description

    useEffect(() => {
        fetch("https://examtice.com/backend/api/affiliate-terms-condition")
            .then(res => res.json())
            .then(data => setAffiliateTermsAndCondition(data))
    }, [])

    console.log(affiliateTermsAndConditions)

    return (
        <>
            <Header />
            <section className="terms-and-condition">
                {/* <div className="container">
                    <h2 className="page-heading">
                        Terms & Conditions - Affiliate Program{" "}
                    </h2>
                    <p>
                        By signing up to be an affiliate in the Examtice Affiliate Program
                        (hereinafter referred to as the “Program”), you agree to be bound by
                        the following Terms and Conditions herein stated. Please ensure that
                        you read them carefully before signing up. These Terms and
                        Conditions is a legally binding agreement between IRIS Edutech, is a
                        company registered in Nigeria and You (name of the Company and or
                        Affiliate).
                    </p>
                    <p>
                        We reserve the right to update and change the Terms and Conditions
                        from time to time without notice. Any amendments, modifications,
                        enhancements or changes to the Program including the release of new
                        features and resources made available by us from time to time shall
                        be subject to these Terms and Conditions. Continued use of the
                        Program after any such changes shall constitute your consent to such
                        changes.
                    </p>
                    <p>
                        Any violation of these Terms may result in, among other things,
                        termination or suspension of your rights to be an Affiliate and
                        forfeiture of any outstanding affiliate referral fee payments earned
                        during the violation.
                    </p>
                    <h6>Account Registration & Terms</h6>

                    <p>
                        1. You must provide your legal full name, a valid email address, and
                        any other information requested to complete the signup process for
                        an Affiliate account (hereinafter referred to as “Account”).
                    </p>
                    <p>2. You must be 18 years of age or older to join this Program.</p>
                    <p>
                        3. Each Account is for use by either a single legal entity (e.g. a
                        company or a partnership) or an individual user. We do not permit
                        you to share your user name and password with any other person nor
                        with multiple users on a network. Responsibility for the security of
                        any user names and passwords issued (including those of any
                        Invitees) rests with you.
                    </p>
                    <p>
                        4. You cannot use the Program for any illegal or unauthorized
                        purpose. You must not, in the use of the Program, violate any laws
                        in your jurisdiction (including but not limited to copyright laws).{" "}
                    </p>
                    <h6>Referral (affiliate) & Promotion</h6>
                    <p>
                        Once you have signed up for the Program you will be responsible to
                        register subscribers to the Examtice Platform.
                    </p>
                    <p>
                        You will be solely responsible for the operation, ensure that the
                        data and information on that platform are not shared.
                    </p>
                    <p>
                        You may not use our name or graphics in any bulk email whatsoever
                        unless we have given our advanced written consent. We may terminate
                        the Agreement if any meaningful spam complaints naming us or our
                        services result from your marketing activities.
                    </p>
                    <p>
                        You may not issue any press release concerning this Agreement or
                        your participation in the Program; such action may result in your
                        termination from the Program. In addition, you may not in any manner
                        misrepresent or embellish the relationship between us and you, say
                        you develop our services, say you are part of IRIS Edutech or
                        express or imply any relationship between us and you or any other
                        person or entity, except as expressly permitted by this Agreement.
                    </p>
                    <h6>Referral Fees</h6>
                    <p>
                        For the sale of a subscription to be eligible to earn a referral
                        fee, the subscriber must be registered through your affiliate
                        account on examtice.com or a customer must communicate to the IRIS
                        Edutech and sign up within 30 days. If they fail to sign up within
                        those 30 days you will not earn a referral fee.
                    </p>
                    <p>
                        We will not pay referral fees if someone says they signed up through
                        you unless that person has communicated to Examtice before signing
                        up.
                    </p>
                    <p>
                        The referral fee is 20% (net) of our revenue from customers that you
                        refer. The referral fee will be credited to your Affiliate account
                        once the customer pays their subscription. Referral fees are only
                        earned if a customer makes a payment in full.
                    </p>
                    <h6>Payment </h6>
                    <p>
                        Accrued referral fees are paid via bank transfer roughly once per
                        month and only when your accrued referral fees total N10,000.00 (Ten
                        Thousand Naira) or more. The Affiliate will then raise an invoice to
                        IRIS EduTech for the indicated amount. You must have a valid bank
                        account to receive referral fees, as we do not offer payment via
                        cheque/check, credit card, cash or other methods.
                    </p>
                    <p>
                        Customer payments refunded or payments charged back due to credit
                        card fraud do not qualify for referral fees. We may delay crediting
                        of referral fees subject to risk analysis considerations and
                        Anti-Money Laundering procedures.
                    </p>
                    <p>
                        A summary of sign-ups and statement of referral fees is available to
                        the Affiliate by logging into their Affiliate account.
                    </p>
                    <p>
                        The referral fee structure is subject to change at our discretion.
                    </p>
                    <p>
                        We reserve the right to disqualify referral fees earned through
                        fraudulent, illegal, or questionable sales or marketing methods.
                    </p>
                    <p>
                        All fees are exclusive of all taxes, charges, levies, assessments
                        and other fees of any kind imposed on your involvement in this
                        Agreement and shall be the responsibility of, and payable by you.
                    </p>
                    <p>
                        We reserve the right to check and change commissions based on orders
                        paid, the notification e-mail is not understood as a confirmed
                        commission - this is only a notification, and every payment will be
                        verified based on real transactions.
                    </p>
                    <h6>Customer Definition</h6>
                    <p>
                        Every customer who buys a service through this program is deemed to
                        be a customer of IRIS Edutech. Accordingly, all of our rules,
                        policies and operating procedures concerning pricing, customer
                        orders, customer service, and services sales will apply to those
                        customers. We may change our policies and operating procedures at
                        any time. IRIS Edutech is not responsible for any representations
                        made by the Affiliate that contradict our rules, policies or
                        operating procedures.
                    </p>
                    <h6>Pricing & Availability</h6>
                    <p>
                        We will determine the prices to be charged for services sold under
                        this Program following our pricing policies. Services prices and
                        availability may vary from time to time. We will use commercially
                        reasonable efforts to present accurate information, but we cannot
                        guarantee the availability or price of any particular service.
                    </p>
                    <h6>Copyrighted and Trademarked material</h6>
                    <p>
                        You are solely responsible for ensuring that your reviews, product
                        descriptions and articles obey all applicable copyright, trademark,
                        and other laws. IRIS Edutech will not be responsible if you use
                        another party's copyrighted or trademarked material in violation of
                        the law.
                    </p>
                    <h6>Term of the Agreement and Program</h6>
                    <p>
                        The term of this Agreement will begin upon our acceptance of your
                        Program application and will end when terminated by either party.
                        Either you or we may terminate this Agreement at any time, with or
                        without cause, by giving the other party notice of termination.
                        Notice by e-mail, to your address on our records, is considered
                        sufficient notice to terminate this Agreement. IRIS Edutech reserves
                        the right to end the Program at any time. Upon Program termination,
                        IRIS Edutech will pay any legitimate outstanding earnings.
                    </p>
                    <h6>Termination</h6>
                    <p>
                        IRIS Edutech, in its sole discretion, has the right to suspend or
                        terminate your account and refuse any current or future use of the
                        Program, or any other IRIS Edutech service, for any reason at any
                        time. Such termination will result in the deactivation or deletion
                        of your Affiliate Account, and the forfeiture and relinquishment of
                        all potential or accrued referral fees in your Account if they were
                        earned through fraudulent, illegal, questionable sales or marketing
                        methods. IRIS Edutech reserves the right to refuse service to anyone
                        for any reason at any time. Upon the termination of this Agreement
                        for any reason, you will immediately cease use of, and remove from
                        your site, all links to the IRIS Edutech Project website and all our
                        images and other materials provided under the Program.
                    </p>
                    <h6>Relationship of Parties</h6>
                    <p>
                        You and we are independent contractors, and nothing in this
                        Agreement Terms and Conditions will create any partnership, joint
                        venture, agency, franchise, sales representative, or employment
                        relationship between the parties. You will have no authority to make
                        or accept any offers or representations on our behalf. You will not
                        make any statement, whether on your site or otherwise, that
                        reasonably would contradict anything in this Agreement.
                    </p>
                    <h6>Limitations of Liability</h6>
                    <p>
                        The Company and any of the Company's officers, directors, employees,
                        shareholders or agents of any of them, exclude all liability and
                        responsibility for any amount or kind of loss or damage that may
                        result to you or a third party (including without limitation, any
                        direct, indirect, punitive or consequential loss or damages, or any
                        loss of income, profits, goodwill, data, contracts, use of money, or
                        loss or damages arising from or connected in any way to business
                        interruption, and whether in tort (including without limitation
                        negligence), contract or otherwise) in connection with this Program.
                    </p>
                    <p>
                        Nothing in this legal notice shall exclude or limit the Company's
                        liability for:
                    </p>
                    <p>(a) death or personal injury caused by negligence; or</p>
                    <p>(b) fraud; or</p>
                    <p>(c) misrepresentation as to a fundamental matter; or</p>
                    <p>
                        (d) any liability which cannot be excluded or limited under
                        applicable law.
                    </p>
                    <p>
                        If your use of material provided under this Program results in the
                        need for servicing, repair or correction of equipment, software or
                        data, you assume all costs thereof.
                    </p>
                    <h6> Arbitration</h6>
                    <p>
                        Any dispute relating in any way to this Agreement (including any
                        actual or alleged breach hereof), any transactions or activities
                        under this Agreement or your relationship with us or any of our
                        affiliates shall be submitted to confidential arbitration in
                        Nigeria. If any difference or dispute arises which dispute cannot be
                        settled amicably by the Parties, the same shall be referred to
                        Arbitration to determine the issues in dispute under relevant
                        provisions of the Arbitration and Conciliation Act, Cap A18, Laws of
                        the Federation of Nigeria 2004.
                    </p>
                    <p>
                        The Arbitrator's award shall be binding and may be entered as a
                        judgment in any court of competent jurisdiction. To the fullest
                        extent permitted by applicable law, no arbitration under this
                        Agreement shall be joined to an arbitration involving any other
                        party subject to this Agreement, whether through class arbitration
                        proceedings or otherwise.
                    </p>
                    <h6>Notice</h6>
                    <p>
                        All notices given by you to us must be given to IRIS Edutech. at
                        support@examtice.com. We may give notice to you at the e-mail
                        address you provided to us when registering. Notice will be deemed
                        received and properly served 24 hours after an e-mail is sent. In
                        proving the service of any notice, it will be sufficient to prove in
                        the case of an e-mail, that such e-mail was sent to the specified
                        e-mail address of the addressee.
                    </p>
                    <h6>Events outside our control</h6>
                    <p>
                        We will not be liable or responsible for any failure to perform or
                        delay in performance of, any of our obligations hereunder that is
                        caused by events outside our reasonable control (a “Force Majeure
                        Event”).
                    </p>
                    <p>
                        A Force Majeure Event includes any act, event, non-happening,
                        omission or accident beyond our reasonable control and includes in
                        particular (without limitation) the following:
                    </p>
                    <p>(a) strikes, lock-outs or other industrial action;</p>
                    <p>
                        (b) civil commotion, riot, invasion, terrorist attack or threat of
                        terrorist attack, war (whether declared or not) or threat or
                        preparation for war;
                    </p>
                    <p>
                        (c) fire, explosion, storm, flood, earthquake, subsidence, epidemic
                        or other natural disasters;
                    </p>
                    <p>
                        (d) impossibility of the use of public or private telecommunications
                        networks;
                    </p>
                    <p>
                        (e) the acts, decrees, legislation, regulations or restrictions of
                        any government.
                    </p>
                    <p>
                        Our performance is deemed to be suspended for the period that the
                        Force Majeure Event continues, and we will have an extension of time
                        for performance for the duration of that period. We will use our
                        reasonable endeavours to bring the Force Majeure Event to a close or
                        to find a solution by which our obligations under these Terms may be
                        performed despite the Force Majeure Event.
                    </p>
                    <h6>Waiver</h6>
                    <p>
                        If we fail, at any time to insist upon strict performance of any of
                        your obligations under these Terms, or if we fail to exercise any of
                        the rights or remedies to which we are entitled hereunder, this
                        shall not constitute a waiver of such rights or remedies and shall
                        not relieve you from compliance with such obligations.
                    </p>
                    <p>
                        A waiver by us of any default shall not constitute a waiver of any
                        subsequent default.
                    </p>
                    <p>
                        No waiver by us of any of these Terms shall be effective unless it
                        is expressly stated to be a waiver and is communicated to you in
                        writing.
                    </p>
                    <h6>Severability</h6>
                    <p>
                        If any of these Terms are determined by any competent authority to
                        be invalid, unlawful or unenforceable to any extent, such term,
                        condition or provision will to that extent be severed from the
                        remaining terms, conditions and provisions which will continue to be
                        valid to the fullest extent permitted by law.
                    </p>
                    <h6>Entire agreement</h6>
                    <p>
                        These Terms Conditions and any document expressly referred to in it
                        represents the entire agreement between us concerning the use of the
                        Program and supersedes any prior agreement, understanding or
                        arrangement between us, whether oral or in writing.
                    </p>
                    <p>
                        We each acknowledge that, in entering into these Terms, neither of
                        us has relied on any representation, undertaking or promise given by
                        the other or be implied from anything said or written in
                        negotiations between us before entering into these Terms except as
                        expressly stated herein.
                    </p>
                    <p>
                        Neither of us shall have any remedy in respect of any untrue
                        statement made by the other, whether orally or in writing, prior to
                        the date we entered into these Terms (unless such untrue statement
                        was made fraudulently) and the other party's only remedy shall be
                        for breach of contract as provided in these Terms.
                    </p>
                    <h6>Governing law and jurisdiction</h6>
                    <p>
                        This legal notice shall be governed by and construed under Nigerian
                        law. Disputes arising in connection with this legal notice shall be
                        subject to the exclusive jurisdiction of the Nigerian Courts.
                    </p>
                </div> */}


                <div className="container">
                    <h2 className="page-heading">{title}</h2>
                    {/* <h2 className="page-heading">General Terms & Conditions</h2> */}
                    {subTitle && <p>{subTitle}</p>}
                    {description && (
                        <div className="termsAndCondition">
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>
                    )}
                </div>

            </section>

            <Footer />
        </>
    );
};

export default AffiliateTermsAndCondition;
