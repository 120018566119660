import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const TermsAndCondition = (props) => {

    const [termsAndConditions, setTermsAndConditions] = useState([])


    const title = termsAndConditions?.data?.title
    const subTitle = termsAndConditions?.data?.sub_title
    const description = termsAndConditions?.data?.description

    // console.log(subTitle)

    useEffect(() => {
        fetch("https://examtice.com/backend/api/terms-condition")
            .then(res => res.json())
            .then(data => setTermsAndConditions(data))
    }, [])

    console.log(termsAndConditions)

    return (
        <>
            <Header />
            <section className="terms-and-condition">
                {/* <div className="container">
                    <h2 className="page-heading">General Terms & Conditions </h2>
                    <p>
                        This General Terms & Conditions is made this day of 10th January
                        2022 for IRIS Edutech which is duly registered in Nigeria.
                    </p>
                    <h6>1. GENERAL</h6>
                    <p>
                        These General Terms and Conditions (hereinafter referred to as
                        “GTC“) shall govern the relationship between the business company,
                        IRIS Edutech (hereinafter referred to as "Seller") and customers of
                        the Seller (hereinafter referred to as “Buyer” and or “User“). The
                        Buyer is obliged to get acquainted with these General Terms and
                        Conditions and agree with them before ordering any goods from the
                        Seller.
                    </p>
                    <p>
                        {" "}
                        The GTC shall be applied to the entire examtice.com website and to
                        all of the services and products (hereinafter referred to jointly as
                        “Goods”) offered and provided by the Seller. For the avoidance of
                        doubt, the Seller proclaims that goods shall also mean for the
                        purposes of the GTC and the contract, also service or digital
                        content supplied by the Seller to the Buyer (primarily through
                        access to the Seller’s databases). By purchasing any goods, the
                        Buyer agrees with these GTC.
                    </p>
                    <h6>2. GENERAL INFORMATION</h6>
                    <p>
                        The Seller shall acknowledge the receipt of the Buyer’s order
                        without undue delay through an email message.
                    </p>
                    <p>
                        Information about goods and prices presented by the Seller is
                        binding except for obvious errors. All displayed prices are final
                        (including VAT where applicable). Other taxes, where applicable,
                        such as import duties and the cost of delivery of goods, are not
                        included in the prices.
                    </p>
                    <p>
                        The purchase contract may be entered into in English. The purchase
                        contract (including GTC) is entered into at the time of receipt of
                        the payment by the Seller from the Buyer.
                    </p>
                    <p>
                        The Seller is entitled to contact the Buyer prior to the shipment of
                        his/her order. This includes problems with information received or
                        not received from Buyer’s order (the Seller may require additional
                        information) and any issues the Seller discovers related to
                        information about goods, availability, or pricing. The Buyer may
                        cancel the order, without penalty, prior to its shipping, for a full
                        refund of the purchase price, less any bank charges incurred by the
                        Seller.
                    </p>
                    <p>
                        Payment terms are within Seller’s sole discretion, and, unless
                        otherwise agreed, payment must be made at the time of purchase.
                        Payment may be made by credit card, wire transfer, or some other
                        prearranged payment method. The Buyer is solely responsible for
                        entering true, correct and full billing + contact details at the
                        time of placing an order, especially with regard to the country of
                        residency and the VAT (Value Added Tax) status (VAT number if
                        applicable) of the Buyer. The Seller does not require any fees
                        depending on the payment method.
                    </p>
                    <h6>3. DELIVERY OF THE GOODS</h6>
                    <p>
                        In general, the Seller shall attempt to deliver the goods to the
                        Buyer complete, within 15 working days as of the confirmation of the
                        order, unless stated otherwise as to the respective goods and with
                        regard to the shipping method selected by the Buyer. If the status
                        of goods is "in stock", the Seller shall dispatch the goods within 2
                        working days. The Buyer shall accept the goods and pay for them. The
                        Buyer shall check the goods immediately after the delivery of the
                        goods. Digital content purchased by the Buyer shall be made
                        available to the Buyer by the Seller immediately after the receipt
                        of the payment.
                    </p>
                    <p>
                        If so requested by the Buyer, the Seller shall confirm in writing
                        the extent and the duration of the Buyer’s claims from liability for
                        defects and the manner in which the Buyer can exercise his/her
                        rights following from the liability.
                    </p>
                    <p>
                        Shipping and handling fees are based on the size and weight of the
                        order and the shipping method which the Buyer chooses for the
                        delivery of the order. Delivery dates stated above (time required
                        for delivery) are estimates only. The Seller shall not be
                        responsible for delays in deliveries by a shipping carrier as it
                        cannot influence these factors. For international orders, the Buyer
                        is responsible to pay all taxes, duties, and brokerage fees, which
                        are in addition to shipping and handling charges applied to the
                        order. The Buyer is solely responsible for correctly stating his/her
                        VAT tax domicile for the purpose of the VAT application.
                    </p>
                    <h6>4. WITHDRAWAL</h6>
                    <p>
                        I. The Buyer may withdraw from the contract within 14 days from the
                        takeover of goods or the last part of the delivery, regardless of
                        the method of receipt of the goods or payment.
                    </p>

                    <p>
                        II. The Buyer may withdraw from the contract any time before
                        dispatch of the goods by the Seller.{" "}
                    </p>
                    <p>
                        III. The Buyer shall send or hand over the withdrawal from the
                        contract to the Seller at the latest within 14 days as of the
                        takeover of the goods. The Buyer is not obliged to state the reason
                        for the withdrawal from the contract. To facilitate communication,
                        the Buyer shall state in the withdrawal from the contract the date
                        of purchase or contract number/sales receipt, bank account and the
                        method of returning the goods which the Buyer chose.
                    </p>
                    <p>
                        IV. The Seller shall return to the Buyer the amount corresponding to
                        the full price of the goods and pay the cost of its delivery
                        (hereinafter referred to as “the Respective Amount”) within 14 days
                        as of the date of delivery of withdrawal from the contract
                        (hereinafter referred to as “Return period”). The Respective Amount
                        shall be returned in the same way as the Seller received the payment
                        from the Buyer. If the Seller offered several options for a certain
                        delivery method, the Seller is obliged to reimburse only the
                        cheapest one. The Buyer shall return the goods to the Seller at the
                        latest within the Return period as well. The goods shall be returned
                        to the Seller complete, preferably in the original packaging and it
                        shall not show signs of wear or damage. If the value of the goods
                        was decreased through the handling or disposition of the goods by
                        the Buyer in a manner which was not necessary with regards to the
                        nature and features of the goods, the Seller is entitled to claim
                        compensation for the decrease of the value of the goods and set it
                        off against the Respective Amount. The same rule shall be applied if
                        the goods were damaged.
                    </p>
                    <p>
                        V. The cost of returning the goods shall be borne (in case of the
                        withdrawal) by the Buyer. The Seller is not obliged to return to the
                        Buyer the Respective Amount unless the Buyer already returned the
                        goods to the Seller.
                    </p>
                    <p>
                        Exceptions: The right to withdraw from the contract shall not be
                        applied to contracts for the supply of digital content if it is not
                        supplied on a tangible medium and the delivery of the digital
                        content takes place, at the Buyer’s express and informed the
                        previous request, before the end of the withdrawal period. The
                        Seller proclaims that it supplies digital content only without
                        tangible medium and the delivery of the digital content before the
                        end of the withdrawal period can take place through examtice.com
                        website only at the Buyer’s express and informed the previous
                        request. Therefore, if the Buyer orders the supply of digital
                        content through examtice.com website and the delivery of the digital
                        content takes place before the end of the withdrawal period, the
                        Buyer is not entitled to withdraw from the contract.
                    </p>
                    <p>
                        The delivery of the form for withdrawal sent by the Buyer shall be
                        acknowledged by the Seller without undue delay through an email
                        message or in writing.
                    </p>
                    <h6>5. LIABILITY FOR DEFECTS</h6>
                    <p>
                        The Seller shall be responsible for the defects of the goods (e.g.
                        if the goods do not have agreed or reasonably expected features, the
                        goods are not complete, the quantity, measure, weight do not
                        correspond with legal, contractual or pre-contractual conditions).
                    </p>
                    <p>
                        If a defect is regarded as a fundamental breach of the contract (no
                        matter if the defect is repairable or irreparable), the Buyer may:
                    </p>
                    <p>I. notify the defect and require (according to his/her choice)</p>
                    <p>
                        II. removal of such defects by delivery of new goods without defects
                        or by delivery of missing goods; or
                    </p>
                    <p>III. removal of such defects by their repair; or</p>
                    <p>IV. reasonable reduction of the purchase price, or</p>
                    <p>
                        V. refund of the purchase price based on withdrawal from the
                        contract.
                    </p>
                    <h6>6. BUYER’S RIGHTS FROM LIABILITY FOR DEFECTS AND WARRANTY</h6>
                    <p>
                        I. The Seller is liable for defects that occurred after the takeover
                        of the goods within the warranty period or at the time of usability
                        stated in advertising, on the packaging or in the attached
                        instructions.
                    </p>
                    <p>
                        II. The Buyer is entitled to require to have the defects repaired
                        free of charge or to obtain a reasonable reduction of the purchase
                        price; if it is not inappropriate to the nature of the defects
                        (especially if it is not possible to repair the defect without undue
                        delay) the Buyer may require the supply of new goods without defects
                        or a new part without defects.
                    </p>
                    <p>
                        III. In case the repair or replacement of the goods is not possible,
                        the Buyer may require a full refund of the purchase price on the
                        basis of withdrawal from the contract.
                    </p>
                    <p>
                        IV. The Seller is not obliged to meet the Buyer's claim if the
                        Seller proves that the Buyer has known about the defects before
                        takeover or the defects has been caused by the Buyer.
                    </p>
                    <p>
                        V. The Seller is not liable for defects that occur as a result of
                        wear and tear or failure to follow the instructions.
                    </p>
                    <h6>7. CLAIM SETTLEMENT</h6>
                    <p>
                        The Buyer is obliged to send a notice of defects (complaint) to the
                        Seller or to the person designated for repair without undue delay
                        after detection of defects. By doing so in writing or
                        electronically, the Buyer should state his/her contact information,
                        description of the defect.
                    </p>
                    <p>
                        The Buyer is obliged to inform the Seller which claim he/she has
                        chosen when he/she notifies the Seller of the defects or without
                        undue delay after sending this notice. The change of asserted claim
                        without the Seller’s consent is possible only if the Buyer required
                        repair of the defect, which turned out to be irreparable.
                    </p>
                    <p>
                        If the claim following from the fundamental breach of contract is
                        not chosen by the Buyer on time, the Buyer shall have only claims
                        following from the non-fundamental breach of contract.
                    </p>
                    <p>
                        The Buyer shall prove the purchase of the goods (preferably by the
                        receipt) upon notification of defects. The period for settling the
                        complaint is running as of the date of delivery of the defective
                        goods to the Seller (or to the place designated for repair). The
                        goods shall be packed by the Buyer in such a manner as to preserve
                        and protect the goods. The goods shall be clean and complete.
                    </p>
                    <p>
                        The Seller shall immediately, but no later than within three working
                        days, decide on the complaint or as the case may be that it is
                        necessary to make an expert opinion. The Seller shall notify the
                        Buyer of the necessity of the expert opinion. The Seller shall
                        settle the complaint, including the removal of defects, without
                        undue delay, at the latest within 40 days, unless agreed otherwise
                        with the Buyer. The failure to fulfil this duty shall be regarded as
                        a fundamental breach of contract.
                    </p>
                    <p>
                        If the Seller refuses to remove the defect, the Buyer is entitled to
                        require a reasonable reduction of the purchase price or to withdraw
                        from the contract.
                    </p>
                    <p>
                        The warranty period shall be prolonged by the period running as of
                        the notification of defects (complaint) to its settlement (or until
                        the time the Buyer was obliged to pick up the goods). If the goods
                        or their parts are replaced by the new goods the liability for
                        defects of the Seller shall be the same as in case of the new goods
                        or their parts.
                    </p>
                    <h6>8. MISCELLANEOUS</h6>
                    <p> PRIVACY & SECURITY POLICY</p>
                    <p> I. Personal Data Security </p>
                    <p>
                        The Seller recognizes the importance of protecting the Buyer’s
                        privacy. Any registration, personal, or other information Buyer
                        shares with the Seller is securely managed and safeguarded.
                    </p>
                    <p>
                        To maintain the accuracy of the Personal Data, as well as to prevent
                        unauthorised access and ensure the correct use of Personal Data, the
                        Seller has implemented appropriate physical, technical, and
                        administrative measures to safeguard and secure the Personal Data we
                        collect.
                    </p>
                    <p>
                        For example, the Seller uses Secure Socket Layer (SSL) protocol - an
                        industry standard for encryption over the Internet - to protect in
                        the transmission of Personal Data collected online. All electronic
                        Personal Data that the Seller maintains is securely stored and
                        further protected through our use of appropriate access controls.
                    </p>
                    <p> II. Disclosure and Transfer of Personal Data</p>
                    <p>
                        No personal financial information (for example credit card numbers)
                        are in any way handled or stored by the Seller (the Seller do not
                        come into contact with Buyer’s credit card information - all
                        payments made through eShop are processed directly by contractual
                        Banks, and not by the Seller).
                    </p>
                    <p>
                        None of the above-mentioned information is provided to other parties
                        unless in cases below mentioned: NIGERIA DATA PROTECTION REGULATION
                        2019 (hereinafter referred to as "NDPR") on the protection of
                        natural persons is to create a regulatory framework for the
                        protection and processing of personal data and safeguard the rights
                        and freedoms of data subjects which are guaranteed under the
                        Nigerian Constitution and other relevant laws in Nigeria.
                    </p>
                    <p>
                        We process your data to conclude a contract, manage a contract, or
                        for negotiating or changing a contract carried out on the proposal
                        of the data subject. In such a case, personal data are processed
                        only to the extent necessary for the conclusion and performance of
                        the contract. These include, in particular, products concerning the
                        preparation for tests, furthermore the sale and offer of products,
                        merchandise, electronic books, textbooks, tests available at
                        examtice.com and other products.
                    </p>
                    <p>
                        For the above-stated purposes, the provision of your data is
                        voluntary, however, it is necessary for the conclusion of the
                        contract, its subsequent administration, where the provision of
                        personal data serves here primarily for the unambiguous and
                        unmistakable identification of the data subject.
                    </p>
                    <p>
                        Personal data are processed to the extent to which the relevant data
                        subject has provided them to the Controller, this namely in
                        connection with the conclusion of the aforementioned contractual
                        legal relationship with the Controller or with the fulfilment of the
                        statutory obligations of the Controller, in particular the relevant
                        laws to ensure the obligations laid down by the tax and related laws
                        of the Federal Republic of Nigeria as amended.
                    </p>
                    <p>
                        The processing of your data is also necessary for the purposes of
                        the legitimate interests of the Controller or of a third party,
                        except in cases where your interests or fundamental rights and
                        freedoms requiring the protection of personal data prevail over such
                        interests, especially if the data subject is a child. This may be
                        the case for legitimate interests: the debt recovery on the data
                        subject (customer), other customer disputes (e.g. reclamation),
                        securing of evidence for the case of necessity to defend the rights
                        of the Controller, debtor records and records of the misusing of
                        access data or products offered by the Controller.
                    </p>
                    <p>
                        If you have given voluntary consent, then we process your personal
                        data for marketing purposes as well, if we have granted consent from
                        you. You can revoke your given consent at any time, even in its
                        parts, in your profile on the website of the Controller or in
                        writing by e-mail to support@examtice.com.{" "}
                    </p>
                    <p>
                        The Controller also uses the services of Google Analytics, Google
                        Adwords, Bing, Quora, Hotjar / Smartlook and Smartsupp for the
                        purposes of marketing or advertising targeting. By browsing these
                        websites, you consent to the use of these services.
                    </p>
                    <p> III. Recipients of Personal Data</p>
                    <p>
                        To ensure the proper operation of the company IRIS Edutech. and be
                        able to provide you with quality and proper service, other third
                        parties have access to your personal data. These are, in particular,
                        IT service providers (the owner of the servers on which the data are
                        stored), bank and payment gateways, law and accounting offices and
                        other persons who provide services to IRIS Edutech and with whom we,
                        of course, have a concluded written contract on the processing of
                        personal data to protect your personal data.
                    </p>
                    <p>
                        The Controller further states that your personal data may be
                        accessed by the State Authorities within the framework of fulfilling
                        the legal obligations stated by the relevant legal regulations.{" "}
                    </p>
                    <p>
                        IV. Categories of Personal Data That Are the Subject Matter of
                        Processing, addressing and identification data serving to the
                        unambiguous and unmistakable identification of the data subject
                        (e.g., name, surname, permanent address) data enabling the contact
                        with the data subject (contact details – e.g., phone number and
                        e-mail address)other and descriptive data (registration date and
                        last login date)operational and location data.
                    </p>
                    <p>
                        These are data processed to fulfil the contract (user login, issues
                        with the providing of service etc.).
                    </p>
                    <p>These are in particular the following data:</p>
                    <p>
                        <ul>
                            <li> date and time of login to the web, or server,</li>
                            <li>IP address</li>
                            <li>browser</li>
                            <li>HTTP user agent</li>
                        </ul>
                    </p>
                    <p>
                        In the case of mobile applications (the system iOS, Mac, Android,
                        Windows):
                    </p>
                    <p>
                        <ul>
                            <li>date of activity </li>
                            <li>type of activity</li>
                            <li>version of the used application</li>
                            <li>type of device</li>
                            <li>version of device operating system</li>
                            <li>IP address</li>
                            <li>DNS</li>
                            <li>
                                UID (unique identifier that serves to verification of the user,
                                device, and application).
                            </li>
                        </ul>
                    </p>
                    <p>V. Method of Personal Data Processing</p>
                    <p>
                        The Controller processes personal data both manually and
                        automatically, and keeps the records of all activities, both manual
                        and automated, in which processing of personal data occurs.{" "}
                    </p>
                    <p>VI. Time of Personal Data Storing </p>
                    <p>
                        We keep your personal information in compliance with legal
                        regulations for the duration of the contract so we could provide you
                        with our services. Upon termination of your contract or the
                        obligations arising from it or related to it, we have your data
                        stored for a period of time that is required and stated in the
                        relevant legal regulations, particularly tax and accounting laws,
                        and further under general limitation periods.
                    </p>
                    <p>
                        If you have provided the consent to with sending of business and
                        marketing messages, this consent is valid for the duration of the
                        use of the products and services of the Controller, as well as for
                        the period of the next 1 year after the termination of the use of
                        the products or services.
                    </p>
                    <p>
                        If you do not sign in to your account for more than one year, so
                        your account will be inactive for the mentioned time, all your data
                        will be deleted, unless you have an active product under your
                        account or your account is associated with a Learning Management
                        System. The obligation under the first paragraph of this point is
                        not affected by this.
                    </p>
                    <p>VII. Rights of Data Subjects</p>
                    <p>
                        You have the right to contact the company IRIS Edutech at any time
                        and request information and access to personal data and its
                        processing. Further, you have the right to the deletion, to the
                        correction of inaccurate or incomplete personal data, the right to
                        limitation of the processing, to objection against the processing,
                        to the information on the fact whether an automated decision is
                        being made, including profiling, the data portability, and the right
                        to withdraw the given consent. You can apply these rights to the
                        Controller contacts stated below. With the eventual complaint, you
                        can also turn to the Office for Personal Data Protection. You also
                        have the right to be notified by us without undue delay about a
                        personal data breach, if this violation would result in a high risk
                        for your rights and freedoms.
                    </p>
                    <p>
                        If you have any queries or comments about the information on
                        personal data processing, please do not hesitate to contact us at
                        the e-mail address support@examtice.com.
                    </p>
                    <h6>9. REGISTRATION & PASSWORD SHARING</h6>
                    <p>
                        The Seller reserves the right to refuse supply of goods (services)
                        or subscription to any person or entity for any or no reason
                        whatsoever. Registration is valid for a single user only. Through
                        the registration process, the user chooses a username and password
                        (his/her account within our system). The user is responsible for
                        maintaining the confidentiality of the username and password and is
                        also fully responsible for all activities that occur under his/her
                        ID or password. The Seller does not allow any of the following:{" "}
                    </p>
                    <p>a) any other person sharing username and password;</p>
                    <p>
                        b) access through a single username and password being made
                        available to multiple users on a network;
                    </p>
                    <p>
                        Seller’s servers use advanced functions for the detection of
                        password sharing. Any such occurrence will automatically be reported
                        to the Seller by the system and the user’s account may become
                        temporarily blocked for further investigation or cancelled without
                        entitlement for refund, or the Seller has the right to limit the
                        number of devices under the terms of the APPLICATIONS GTC from three
                        (3) to one (1). Violation of any of the two articles above will be
                        grounds for account termination without our obligation towards the
                        Buyer for any compensation or reimbursement.
                    </p>
                    <h6>10. APPLICATIONS</h6>
                    <p>
                        User can use his/her account through all software platforms or
                        applications offered by the Seller (iPhone/iPad application, OS X
                        application, MS Windows software). User can be logged in through the
                        online system or through any application. Buyer can use the account
                        for the online system plus a maximum of three (3) devices. This is
                        without prejudice to the Seller's right to reduce the number of
                        devices under REGISTRATION & PASSWORD SHARING GTC. Only after
                        logout, it is possible to log in through another application.
                        Synchronization is provided for all platforms. It is required to run
                        the synchronization at least once a month in order to revalidate the
                        subscription.
                    </p>
                    <h6>11. ACCEPTABLE USE POLICY</h6>
                    <p>
                        The Seller has expended substantial time, effort, and funds to
                        create this website and the goods offered. The website
                        www.examtice.com is fully copyright protected. All material and all
                        of the images located on this website and any materials/products
                        sent to the user by e-mail or delivered in any other form from IRIS
                        Edutech. (hereinafter referred to as the "content") or in any way
                        relating to the website belonging to the Seller. The user may
                        retrieve and display content from the website on a computer screen,
                        print a single copy of individual pages on paper and store such
                        pages for caching purposes only, all for your personal and
                        non-commercial use.
                    </p>
                    <p>
                        The user (Buyer) MAY NOT do any of the following without prior
                        written permission from the Seller:
                    </p>
                    <p>
                        reproduce, modify, commercially exploit, redistribute,
                        mass-download, reuse, reproduce, repost, sell, publish or otherwise
                        circulate any of the content, system or parts of the content or
                        system (including using it as part of any library, archive or
                        similar service) remove copyright or trademarks from any material or
                        copies of content made or obtained in accordance with these GTC
                        create a database in an electronic or structured format by
                        systematically downloading and storing the content or parts of the
                        content, use the content on any other website or another networked
                        computer environment.
                    </p>
                    <p>By accessing the website, examtice.com, the user agrees not to:</p>
                    <p>
                        A. damage, modify, interfere with or disrupt access to the website
                        or do anything which may interrupt or impair its functionality;
                    </p>
                    <p>
                        B. make any commercial or business use of the website and its
                        content or resell or commercially benefit from any part or aspect of
                        the website;
                    </p>
                    <p>
                        C. publish, post, distribute, or otherwise transmit defamatorily,
                        offensive, infringing, obscene, indecent or other unlawful or
                        objectionable material or information;
                    </p>
                    <p>
                        The Seller is entitled to prevent the user from accessing the
                        website, without prejudice to any of Seller’s accrued rights, if the
                        user is contravening this Acceptable Use Policy or any other term or
                        condition of these GTC. If Buyer’s access privileges are terminated
                        on the basis of this paragraph, the Seller will hold no obligation
                        for compensation for any of the user’s claims.
                    </p>
                    <h6>12. LIABILITIES</h6>
                    <p>
                        The Seller takes all such steps as are reasonably necessary to
                        provide a fast and reliable service, but exclude to the fullest
                        extent permitted by law any liability for the security of the
                        services on the website or for any disruption of the website however
                        caused, loss of or corruption of any material in transit, or loss of
                        or corruption of material when downloaded onto any computer system.
                    </p>
                    <p>
                        All of the goods (content) offered through the website examtice.com
                        or by the Seller has been thoroughly proofread, examined and checked
                        for inaccuracies and errors. However, mistakes can happen. If you
                        believe you have found incorrectly presented data, please, let us
                        know by e-mail at: support@examtice.com and we will address the
                        issue promptly.
                    </p>
                    <h6>13. LINKED SITES</h6>
                    <p>
                        Certain links are provided with that may lead to websites maintained
                        by third parties over which the Seller has no control. The Seller
                        takes no responsibility for the accuracy, content, or any aspect of
                        that material, and disclaims any liability to the user for such
                        material or for any consequence of the user’s decision to use the
                        links provided or use of such material.
                    </p>
                    <h6>14. TERMINATION </h6>
                    <p>
                        The Seller is entitled to terminate user’s access to the website
                        and/or other products or services without any prior notice to the
                        user where (by way of example and without limitation): there is a
                        regulatory or statutory change limiting Seller’s ability to provide
                        a website; or user is abusing the services (goods) provided or are
                        otherwise acting in breach of these GTC.
                    </p>
                    <h6>15. ORGANIZATIONS / LEARNING MANAGEMENT SYSTEM SECTION</h6>
                    <p>
                        The Seller stores test results and study plans of students for at
                        least seven (5) years. In case of termination of cooperation, the
                        Seller enables the organization access to the results of the
                        organization's students for the next three (2) years.
                    </p>
                    <p>
                        The organization is responsible for the security of the data
                        exported from the Seller’s platform howsoever (e.g. printed report,
                        electronic document, electronically exported data to another
                        system).
                    </p>
                    <h6>16. CONTACT</h6>
                    <p>
                        If you have any questions concerning any part of this GTC please
                        contact us by email at: support@examtice.com
                    </p>
                    <h6>17. CLOSING PROVISIONS</h6>
                    <p>
                        All disputes arising from the contract entered into between the
                        Seller and the Buyer (user) and/or in connection with it shall be
                        decided by the Nigerian Courts in Abuja.
                    </p>
                    <p>
                        This General Terms and Conditions, including its components, are
                        valid and effective as of 10 January 2022.
                    </p>
                </div> */}
                <div className="container">
                    <h2 className="page-heading">{title}</h2>
                    {/* <h2 className="page-heading">General Terms & Conditions</h2> */}
                    {subTitle && <p>{subTitle}</p>}
                    {description && (
                        <div className="termsAndCondition">
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>
                    )}
                </div>
            </section>

            <Footer />
        </>
    );
};

export default TermsAndCondition;
